<template>
  <v-sheet
    class="overflow-hidden"
    v-bind="heroProps"
  >
    <v-container class="relative lg:fill-height">
      <v-row align-lg="center">
        <v-col cols="24">
          <v-row no-gutters>
            <v-col
              cols="24"
              lg="12"
              xl="14"
              xxl="16"
            >
              <u-text
                color="type-primary"
                class="max-lg:text-center text-heading-h1 mb-4 lg:mb-10"
                tag="h1"
              >
                <u-content :document="blok.title" />
              </u-text>
            </v-col>
          </v-row>

          <v-sheet
            class="max-lg:p-6"
            rounded="4"
            :color="mdAndDown ? 'surface-bg-secondary' : 'white'"
          >
            <u-text
              color="type-secondary"
              class="max-lg:text-center lg:text-body-lead lg:font-bold mb-4"
            >
              <u-content :document="blok.subtitle" />
            </u-text>

            <v-row no-gutters>
              <v-col
                cols="24"
                lg="12"
                xl="9"
                xxl
              >
                <v-row class="-m-2">
                  <template
                    v-for="(btn, index) in heroBtns"
                    :key="index"
                  >
                    <v-col
                      cols="24"
                      md="12"
                      xxl="auto"
                      class="p-2"
                    >
                      <u-btn
                        size="large"
                        :block="xlAndDown"
                        :text="btn.text"
                        v-on="btn.on"
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>

          <trustpilot-widget
            class="mt-10"
            schema-type="Organization"
            style-height="90px"
            style-width="150px"
            template-id="53aa8807dec7e10d38f59f32"
          />
        </v-col>
      </v-row>

      <v-img
        v-if="lgAndUp"
        :src="heroImg"
        rounded="circle"
        max-height="none"
        max-width="none"
        height="auto"
        :aspect-ratio="800 / 812"
        absolute
        class="z-0 lg:top-20 xl:top-4 lg:left-auto lg:-right-[124px] xl:-right-[158px] xxl:-right-[calc(12.5%+158px)] max-lg:-mb-29"
        cover
        v-bind="imgProps"
      >
        <template #sources>
          <source :srcset="heroImgWebp">
        </template>
      </v-img>
    </v-container>

    <v-container>
      <partners-section />
    </v-container>

    <v-img
      v-if="mdAndDown"
      :src="heroImg"
      rounded="circle"
      max-height="none"
      max-width="none"
      height="auto"
      :aspect-ratio="800 / 812"
      cover
      v-bind="imgProps"
      class="mt-16 -mx-10 -mb-30 sm:-mb-[250px] sm:-mx-25 md:-mx-10"
    >
      <template #sources>
        <source :srcset="heroImgWebp">
      </template>
    </v-img>
  </v-sheet>
</template>

<script setup lang="ts">
import heroImg from '#ab-tests/assets/images/hero.jpg'
import heroImgWebp from '#ab-tests/assets/images/hero.webp'
import type { HomeLayoutStoryblok } from '#shared/types/storyblok-component-types'

defineProps<{
  blok: HomeLayoutStoryblok
}>()

const { v2Url } = useRuntimeConfig().public
const { xlAndDown, lg, xlAndUp, lgAndUp, mdAndDown } = useDisplay()
const { trackEvent } = useGtm()
const heroBtns = computed(() => ([
  {
    text: 'Financial adviser',
    on: {
      click: () => {
        trackEvent({
          event: 'gaEvent',
          eventAction: 'click',
          eventCategory: 'homepage',
          eventLabel: 'homepage_fifa_cta',
        })
        goToMatchIntroPage('financial-adviser')
      },
    },
  },
  {
    text: 'Mortgage broker',
    on: {
      click: () => {
        trackEvent({
          event: 'gaEvent',
          eventAction: 'click',
          eventCategory: 'homepage',
          eventLabel: 'homepage_fama_cta',
        })
        goToMatchIntroPage('mortgage-adviser', 'mortgages')
      },
    },
  },
  {
    text: 'Insurance broker',
    on: {
      click: () => {
        trackEvent({
          event: 'gaEvent',
          eventAction: 'click',
          eventCategory: 'homepage',
          eventLabel: 'homepage_insurance_cta',
        })
        goToMatchIntroPage('financial-adviser', 'insurance-and-protection')
      },
    },
  },
  {
    text: 'Accountant',
    on: {
      click: () => {
        trackEvent({
          event: 'gaEvent',
          eventAction: 'click',
          eventCategory: 'homepage',
          eventLabel: 'homepage_faa_cta',
        })
        goToMatchIntroPage('accountant')
      },
    },
  },
]))
const imgProps = computed(() => {
  if (lg.value) {
    return {
      width: 621,
    }
  }

  if (xlAndUp.value) {
    return {
      width: 800,
    }
  }

  return {}
})
const heroProps = computed(() => {
  if (lg.value) {
    return {
      height: 649,
    }
  }

  if (xlAndUp.value) {
    return {
      height: 620,
    }
  }

  return {}
})

function goToMatchIntroPage(serviceType: string, primaryAdviceArea: string | null = null) {
  const url = `${v2Url}/enquiry/match-intro/${serviceType}`

  return navigateTo({
    path: url,
    query: {
      ...(primaryAdviceArea !== null && { primaryAdviceArea }),
    },
  }, {
    external: true,
  })
}
</script>
