<template>
  <v-sheet
    rounded="4"
    class="p-8 xl:max-xxl:h-full"
    color="surface-elevated-4"
  >
    <v-row
      no-gutters
      class="gap-8 h-full"
    >
      <v-col
        v-if="mdAndUp"
        cols="auto"
        xl="24"
        xxl="auto"
      >
        <v-img
          :src="reviewGraham"
          :draggable="false"
          alt="Graham"
          rounded="2"
          color="#F89F83"
          :aspect-ratio="1"
          v-bind="imgProps"
        >
          <template #sources>
            <source :srcset="reviewGrahamWebp">
          </template>
        </v-img>
      </v-col>

      <v-col
        xl="24"
        xxl
        class="flex flex-col"
      >
        <u-text class="text-body-lead font-bold italic grow mb-6">
          "Answered my call within seconds. Professional, experienced and genuinely caring."
        </u-text>
        <u-text
          class="text-body-lg max-sm:mt-4"
          text="Graham (via Trustpilot)"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script setup lang="ts">
import reviewGraham from '#ab-tests/assets/images/review-graham.png'
import reviewGrahamWebp from '#ab-tests/assets/images/review-graham.webp'

const { xxl, lgAndDown, mdAndUp } = useDisplay()
const imgProps = computed(() => {
  if (lgAndDown.value) {
    return {
      minWidth: 272,
      height: '100%',
      position: 'top',
      aspectRatio: 1,
      cover: true,
    }
  }

  if (xxl.value) {
    return {
      width: 200,
    }
  }
  return {
    width: '100%',
  }
})
</script>
